import { FormControl, FormGroup } from '@maplix/forms';
import { I18n } from './models';

export function convertToI18nFormGroups(items: I18n[]) {
  return items.map((item) => {
    return new FormGroup<I18n>({
      i18n: new FormControl(item.i18n),
      value: new FormControl(item.value),
    });
  });
}
